@import "../base/style.less";
@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700');

// Multishop variables and custom styles
@brand-primary: #FF6A10;

@navigation-bg:     #fff;
@navigation-accent: @brand-primary;

@navigation-link-color:        @text-color;
@navigation-link-hover-color:  #fff;
@navigation-link-current-color: @brand-primary;

@mobile-nav-bg: @text-color;

@link-color:       @brand-primary;
@link-hover-color: darken(@brand-primary, 5%);

@sidebar-border: 1px solid @border-color;

@body-bg: #f2f2f2;

@font-family-base: "Dosis", sans-serif;

.main-header {
    border-bottom: 1px solid @border-color;
}
